import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['check'];

  toggle(e) {
    const checked = $(e.target).prev();
    $(checked).prop('checked', !$(checked).prop('checked'));
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
