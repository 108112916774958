import { Controller } from '@hotwired/stimulus';
import countryFlags from '@/data/country_flags';

export default class extends Controller {
  static targets = ['state', 'city'];

  static values = {
    country: {
      type: String,
      default: '',
    },
  };

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    this.loadStates();
    this.loadCities();
  }

  loadStates() {
    $('body').on('change', '.country-location', ({ target }) => {
      const countryCode = $(target).val();

      const url = `/locations/states?country_code=${countryCode}`;
      fetch(url, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.csrfToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const stateData = data.map((state) => ({
            id: state.code,
            text: state.name,
          }));

          $(this.stateTarget).find('option:not(:first)').remove();
          $(this.cityTarget).find('option:not(:first)').remove();

          $(this.stateTarget).select2({ data: stateData });
        });
    });
  }

  loadCities() {
    $('body').on('change', '.state-location', ({ target }) => {
      const countryCode = $('.country-location').val() || this.countryValue;
      const stateCode = $(target).val();

      const url = `/locations/cities?state_code=${stateCode}&country_code=${countryCode}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.csrfToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          $(this.cityTarget).find('option:not(:first)').remove();

          $(this.cityTarget).select2({ data });
        });
    });
  }

  phoneTargetConnected(element) {
    const {
      dataset: { phone, alpha2, countryCode },
    } = element;

    element.innerHTML = `<span class='flex gap-2'>${countryFlags[alpha2]} +${countryCode} ${phone}</span>`;
  }
}
